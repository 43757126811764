import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../layout/layout"
import PageHeader from "../pageHeader"
import TextLeftImageRight from "../contentTemplates/textLeftImageRight"
import TextRightImageLeft from "../contentTemplates/textRightImageLeft"
import FullWidthImageWithText from "../contentTemplates/fullWidthImageWithText"
import styled from "styled-components"
import RowOfThirds from "../contentTemplates/rowOfThirds"
import RowOfTextBlocks from "../contentTemplates/rowOfTextBlocks"
import TextWithCTA from "../contentTemplates/textWithCTA"
import SEO from "../seo"
import QuestionsAndAnswers from "../contentTemplates/QuestionsAndAnswers";

const sliceToComponentMapper = {
  header: PageHeader,
  text_left_image_right: TextLeftImageRight,
  text_right_image_left: TextRightImageLeft,
  full_width_image_with_text: FullWidthImageWithText,
  row_of_3rds: RowOfThirds,
  text_blocks: RowOfTextBlocks,
  text_with_cta: TextWithCTA,
  q_a: QuestionsAndAnswers
}

const ContentPage: React.FC<{ data: any }> = ({ data }) => {
  const { uid, data: templateData } = data.prismicContentTemplate
  const bannerData = templateData.body.find(
    slice => slice.slice_type === "book"
  )
  return (
    <Layout bookBannerData={bannerData ? bannerData.primary : undefined}>
      <SEO title={uid} />
      <Container>
        {templateData.body
          .filter(slice => slice.slice_type !== "book")
          .map(({ items, primary, slice_type }, i) => {
            const Component = sliceToComponentMapper[slice_type]
            return (
              <Component
                key={slice_type + i.toString()}
                {...{ items, ...primary }}
              />
            )
          })}
      </Container>
    </Layout>
  )
}

export default withPreview(ContentPage)

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 8px;

  @media (max-width: 800px) {
    max-width: 500px;
  }
`

export const query = graphql`
  query ContentTemplate($uid: String!) {
    prismicContentTemplate(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        body {
          ... on PrismicContentTemplateBodyHeader {
            id
            slice_type
            primary {
              title {
                html
              }
              tagline {
                html
                text
              }
              text {
                html
                text
              }
            }
          }
          ... on PrismicContentTemplateBodyTextLeftImageRight {
            id
            slice_type
            primary {
              title {
                html
              }
              text {
                html
              }
              optional_link {
                url
                link_type
              }
              link_type
            }
            items {
              image {
                fluid {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          ... on PrismicContentTemplateBodyTextRightImageLeft {
            id
            items {
              image {
                url
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
            slice_type
            primary {
              title {
                html
              }
              text {
                html
              }
              optional_link {
                url
                link_type
              }
              link_type
            }
          }
          ... on PrismicContentTemplateBodyFullWidthImageWithText {
            id
            slice_type
            primary {
              title {
                html
              }
              text {
                text
              }
              text_placement
              image {
                url
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicContentTemplateBodyRowOf3rds {
            id
            slice_type
            primary {
              order
              row_count
            }
            items {
              title {
                html
              }
              text {
                html
              }
              optional_link {
                url
                link_type
              }
              link_type
              image {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicContentTemplateBodyTextBlocks {
            id
            slice_type
            items {
              type
              header {
                text
              }
              text {
                text
              }
            }
          }
          ... on PrismicContentTemplateBodyTextWithCta {
            id
            slice_type
            primary {
              big_text {
                text
              }
              small_text {
                text
              }
              cta_text
              link {
                url
                link_type
              }
              link_type
            }
          }
          ... on PrismicContentTemplateBodyBook {
            id
            primary {
              button_text
              text {
                text
              }
              link {
                link_type
                url
              }
            }
            slice_type
          }
            ... on PrismicContentTemplateBodyQA {
                slice_type
                slice_label
                primary {
                    title {
                        html
                    }
                    text {
                        html
                    }
                }
                items {
                    question {
                        html
                        text
                    }
                    answer {
                        html
                        text
                    }
                }
            }
        }
      }
    }
  }
`
