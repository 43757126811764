import React from "react";
import styled from "styled-components";
import { Answer, Question, QuestionNumber } from "../faq";

const QuestionsAndAnswers = ({ title, text, items }) => {
  return (
    <Container>
      <Header>
        {title ? <Title dangerouslySetInnerHTML={{ __html: title.html }} /> : null}
        {text ? <Text dangerouslySetInnerHTML={{ __html: text.html }} /> : null}
      </Header>
      <Wrapper>
        {items.map(({ question, answer }, i) =>  (<>
            <QuestionContainer key={i}>
              <QuestionNumber>{i + 1}.</QuestionNumber>
              <Question dangerouslySetInnerHTML={{ __html: question.html }} />
            </QuestionContainer>
            <Answer dangerouslySetInnerHTML={{ __html: answer.html }} />
          </>))}
      </Wrapper>
    </Container>
  );
};

export default QuestionsAndAnswers;

const Container = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 40px 8px 24px 8px;
  * {
    color: var(--primary-text);
  }
`;

const Header = styled.div`
  padding: 40px 16px 0 16px;
`
const Title = styled.div`
  //line-height: 24px;
  margin-bottom: 1rem;
`
const Text = styled.div`
  text-align: center;
  line-height: 24px;
`
const QuestionContainer = styled.div`
  display: flex;
  align-items: baseline;
`
const Wrapper = styled.div`
  padding: 40px 16px;
  margin: 0 auto;
  iframe, img {
    width:100%;
    margin: 0 auto;
    min-height: 600px;
  }
  p {
    margin-bottom: 1rem;
  }
`;