import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const TextWithCTA: React.FC = props => {
  const { big_text, small_text, cta_text, link, link_type } = props as any
  const getLinkType = () => {
    if (link_type === "external") {
      return (
        <ExternalCTA href={link.url} target="_blank" rel="noreferrer noopener">
          {cta_text}
        </ExternalCTA>
      )
    }
    return <InternalCTA to={link.url}>{cta_text}</InternalCTA>
  }
  return (
    <Container
      style={{
        padding: cta_text !== "Contact Reservations" ? "80px 0" : "0 0 80px 0",
      }}
    >
      {big_text.text.length !== 0 ? <BigText>{big_text.text}</BigText> : null}
      {small_text.text.length !== 0 ? (
        <SmallText>{small_text.text}</SmallText>
      ) : null}
      {cta_text ? getLinkType() : null}
    </Container>
  )
}

export default TextWithCTA

const Container = styled.div`
  text-align: center;
  padding: 80px 0;
  max-width: 800px;
  margin: 0 auto;
`

const BigText = styled.p`
  font-size: 28px;
  font-family: "Canela Light", sans-serif;
  color: var(--primary-text);
  padding-bottom: 1rem;
`
const SmallText = styled.p`
  color: var(--primary-text);
  padding-bottom: 1rem;
`

const ExternalCTA = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  background: var(--primary-text);
  padding: 15px 24px;
  max-width: 170px;
  margin: 0 auto;
`
const InternalCTA = styled(Link)`
  display: block;
  text-decoration: none;
  color: white;
  background: var(--primary-text);
  padding: 15px 24px;
  max-width: 170px;
  margin: 0 auto;
  transition: all 200ms ease-in-out;
  :hover {
    transition: all 200ms ease-in-out;
    background: var(--primary-blue);
  }
`
